import React, { useEffect, useState } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { LASVEGAS_EN, LASVEGAS_PT } from "../util/constants";
import Button from "@mui/material/Button";
import { Container, Typography, Paper, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingComponent from "./LoadingComponent";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import AILoading from "./AILoading";

const AiwithText = ({ language, description }) => {
  const genAI = new GoogleGenerativeAI(
    "AIzaSyA0vYxiLppLFgJ2-SNzvvB3U4WpAfgOsqI"
  );

  const [userInput, setUserInput] = useState("");
  const [userInputAppendix, setUserInputAppendix] = useState("");
  const [aiOutput, setAiOutput] = useState("");
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(""); // Estado para a mensagem de cópia
  const [formattedAiOutput, setFormattedAiOutput] = useState("");

  const [openModal, setOpenModal] = useState(false); // Estado para controlar o modal
  const [tag, setTag] = useState(""); // Estado para o campo de tag
  const [editableEmail, setEditableEmail] = useState(""); // Estado para o texto editável no modal
  const [plainTextOutput, setPlainTextOutput] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    if (aiOutput) {
      const formatted = formatText(aiOutput);
      setFormattedAiOutput(formatted);

      // Função para converter HTML em texto plano
      const convertHtmlToText = (html) => {
        return html
          .replace(/<br\/?>/gi, "\n") // Converte quebras de linha
          .replace(/<ul>|<\/ul>|<li>|<\/li>/gi, "") // Remove tags de lista
          .replace(/<strong>(.*?)<\/strong>/gi, "$1") // Remove negrito e mantém o texto
          .replace(/<[^>]+>/g, ""); // Remove qualquer outra tag HTML
      };

      // Converte o conteúdo HTML de aiOutput para texto plano
      setPlainTextOutput(convertHtmlToText(aiOutput));
    }
  }, [aiOutput]);

  async function aiRun() {
    setLoading(true);
    setAiOutput("");

    try {
      const template = language === "Português" ? LASVEGAS_PT : LASVEGAS_EN;

      const aiFile = template
        .replace("#EMAIL#", userInput)
        .replace("#language#", language.language)
        .replace("#PRICESLIST#", description)
        .replace("#APPENDIXUSER#", userInputAppendix);

      console.error("AI text: ", aiFile);

      const model = genAI.getGenerativeModel({ model: "gemini-pro" });

      const prompt = `
Please generate a professional email based on the following details:
- Purpose: Respond to a service inquiry.
- Include: Prices, service options, and any necessary disclaimers.
- Tone: Professional and polite.
- Data: ${JSON.stringify(aiFile)}
`;

      const finalPrompt = `${prompt}`;

      const result = await model.generateContentStream(finalPrompt, {
        temperature: 0.0,
      });
      const response = await result.response;
      const responseFromGemini = await response.text();

      console.log("responseFromGemini", responseFromGemini);
      setAiOutput(formatText(responseFromGemini)); // Formata o texto gerado
    } catch (error) {
      console.error("Error during generative AI call:", error);
      setAiOutput("Erro: Ocorreu um erro. Tente novamente.");
    } finally {
      setLoading(false);
    }
  }

  function formatText(text) {
    // Remover caracteres indesejados, como bolinhas ou símbolos estranhos
    const unwantedCharacter = /•/g; // Aqui você pode ajustar o caractere indesejado que quer remover
    let cleanedText = text.replace(unwantedCharacter, "");

    // Substitui **texto** por <strong>texto</strong> (negrito)
    let formattedText = cleanedText.replace(
      /\*\*(.*?)\*\*/g,
      "<strong>$1</strong>"
    );

    // Substitui *texto* por <li>texto</li> dentro de uma <ul> para criar listas não ordenadas
    formattedText = formattedText.replace(
      /\* (.*?)(?=\*)/g,
      "<ul><li>$1</li></ul>"
    );

    // Substitui quebras de linha (\n) para <br> para manter a formatação de parágrafos
    formattedText = formattedText.replace(/\n/g, "<br/>");

    return formattedText;
  }

  // Função para copiar o texto formatado em HTML
  const handleCopy = async () => {
    try {
      // Usamos a API Clipboard para copiar como HTML
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([formattedAiOutput], { type: "text/html" }),
        }),
      ]);
      // Define a mensagem de sucesso
      setCopySuccess("Texto copiado com sucesso!");

      // Remove a mensagem de sucesso após 5 segundos
      setTimeout(() => {
        setCopySuccess("");
      }, 5000);
    } catch (error) {
      console.error("Erro ao copiar o texto:", error);
    }
  };

  const handleChangeSearch = (e) => {
    setUserInput(e.target.value);
  };

  useEffect(() => {
    const savedValue = localStorage.getItem("userInputAppendix");
    if (savedValue) {
      setUserInputAppendix(savedValue);
    }
  }, []);

  const handleChangeApendSearch = (e) => {
    setUserInputAppendix(e.target.value);

    localStorage.setItem("userInputAppendix", e.target.value);
  };

  const handleClick = () => {
    aiRun();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //event.preventDefault();
      // handleClick();
    }
  };

  const handleOpenModal = () => {
    setEditableEmail(formattedAiOutput);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSaveEmail = async () => {
    setLoadingSave(true); // Ativa o diálogo de carregamento

    try {
      await axios.post("https://bramer.tech/emails.php", {
        action: "insert_email",
        tag,
        email: plainTextOutput,
      });

      setTag("");
      setPlainTextOutput("");
      setOpenModal(false);
    } catch (error) {
      console.error("Error saving email:", error);
    } finally {
      setLoadingSave(false); // Desativa o diálogo de carregamento
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}></div>

      {loading ? (
        <p>
          <AILoading />
        </p>
      ) : (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 0, width: "100%" },
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 0, width: "100%", marginBottom: 4 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Adicione complemento para gerar email"
              id="outlined-basic"
              value={userInputAppendix}
              onChange={handleChangeApendSearch}
              rows={3}
              cols={150}
              multiline
              onKeyPress={handleKeyPress}
            />
          </Box>

          <TextField
            label="Cole o email aqui"
            id="outlined-basic"
            value={userInput}
            onChange={handleChangeSearch}
            rows={15}
            cols={150}
            multiline
            onKeyPress={handleKeyPress}
          />
        </Box>
      )}

      <Box
        component="form"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          "& > :not(style)": { marginTop: 4 },
        }}
        noValidate
        autoComplete="off"
      >
        <Button
          variant="outlined"
          color="primary"
          sx={{ marginTop: "20px" }}
          onClick={handleClick}
        >
          Gerar Email
        </Button>
      </Box>

      {aiOutput && (
        <div style={{ margin: "0px 0" }}>
          <Container maxWidth="xl">
            <Paper
              elevation={3}
              style={{ padding: 20, marginTop: 20, marginBottom: 20 }}
            >
              <Typography variant="h5" gutterBottom>
                AI Email
              </Typography>

              {/* Exibição do texto formatado */}
              <div
                style={{
                  whiteSpace: "pre-wrap",
                }}
                dangerouslySetInnerHTML={{ __html: aiOutput }} // Exibe o texto formatado
              />

              {/* Botão de copiar */}
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginTop: "20px" }}
                onClick={handleCopy}
              >
                Copiar Texto
              </Button>

              <Button
                variant="contained"
                color="secondary"
                sx={{ marginTop: "20px" }}
                onClick={handleOpenModal}
              >
                Salvar Email
              </Button>

              {copySuccess && (
                <Typography
                  variant="body2"
                  color="success.main"
                  sx={{ marginTop: "10px" }}
                >
                  {copySuccess}
                </Typography>
              )}

              {/* Modal para editar e salvar email */}
              <Dialog
                open={openModal}
                onClose={handleCloseModal}
                fullWidth
                maxWidth="xl"
                sx={{ maxHeight: "90vh", height: "90vh" }}
              >
                <DialogTitle>
                  Salvar Email
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{ position: "absolute", right: 8, top: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <TextField
                    label="Tag"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Conteúdo do Email"
                    multiline
                    rows={10}
                    value={plainTextOutput}
                    onChange={(e) => setPlainTextOutput(e.target.value)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleSaveEmail}
                    color="primary"
                    variant="contained"
                  >
                    Salvar
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Diálogo de Carregamento */}
              <Dialog open={loadingSave} fullWidth maxWidth="sm">
                <DialogContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100px",
                  }}
                >
                  <CircularProgress />
                </DialogContent>
              </Dialog>
            </Paper>
          </Container>
        </div>
      )}
    </div>
  );
};

export default AiwithText;
