import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

const AILoading = ({ mensagem = "Gerando seu e-mail..." }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: "linear-gradient(135deg, #f9f9f9, #eaeaea)", // Gradiente suave
        color: "#444",
        textAlign: "center",
        padding: 2,
      }}
    >
      {/* Loader animado */}
      <CircularProgress
        size={80}
        thickness={5}
        sx={{
          color: "#666", // Cor neutra
        }}
      />
      {/* Mensagem Principal */}
      <Typography
        variant="h6"
        sx={{
          fontSize: "1.5rem",
          fontWeight: "500",
          marginTop: 2,
        }}
      >
        {mensagem}
      </Typography>
      {/* Mensagem Secundária */}
      <Typography
        variant="body1"
        sx={{
          fontSize: "1rem",
          opacity: 0.7,
          marginTop: 1,
        }}
      >
        Por favor, aguarde enquanto a IA processa sua solicitação.
      </Typography>
    </Box>
  );
};

export default AILoading;
