import React, { useState, useEffect } from "react";
import AiwithText from "../components/AiwithText";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  ListItem,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import axios from "axios";
import CopilotStyleAutocomplete from "../components/CopilotStyleAutocomplete";
import AutoCopilot from "../components/AutoCopilot.tsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoComplete from "../components/AutoComplete.js";

const EmailAi = () => {
  const [prices, setPrices] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedLanguageOption, setSelectedLanguageOption] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [priceData, setPriceData] = useState();

  const optionsLanguage = ["Português", "Inglês"];

  useEffect(() => {
    // Fetch data from the API
    const fetchPrices = async () => {
      try {
        const response = await axios.post(
          "https://bramer.tech/prices_list.php",
          { action: "fetch" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setPrices(response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    fetchPrices();
  }, []);

  const handleSelect = (event, value) => {
    if (value) {
      setSelectedLanguageOption(value);
    }
  };

  const flattenJSON = (data) => {
    const flatList = [];

    const processNode = (
      node,
      currentPath = [],
      category = "N/A",
      subCategory = "N/A"
    ) => {
      if (typeof node !== "object" || node === null) {
        // Se for um valor direto, adiciona ao flatList
        flatList.push({
          path: currentPath.join(" > "),
          category,
          subCategory,
          type: currentPath[currentPath.length - 1] || "Unknown",
          price: node || "N/A",
        });
        return;
      }

      // Itera sobre os objetos internos
      Object.entries(node).forEach(([key, value]) => {
        const updatedPath = [...currentPath, key];
        processNode(
          value,
          updatedPath,
          category === "N/A" ? key : category,
          subCategory === "N/A" && category !== key ? key : subCategory
        );
      });
    };

    processNode(data);
    console.log("flatList: " + JSON.stringify(flatList, null, 2));

    return flatList;
  };

  const convertToPriceData = (jsonString) => {
    const priceData = [];
    const jsonData = JSON.parse(jsonString); // Converter string JSON para objeto

    const traverse = (obj, parentKey = "") => {
      for (const key in obj) {
        const currentKey = parentKey ? `${parentKey} > ${key}` : key;

        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          traverse(obj[key], currentKey); // Descer na hierarquia
        } else if (typeof obj[key] === "string" && obj[key].startsWith("$")) {
          // Adicionar ao array plano
          priceData.push({
            event: parentKey,
            pax: key,
            price: obj[key],
          });
        }
      }
    };

    traverse(jsonData);
    setPriceData(priceData);
    console.log(priceData);
    return priceData;
  };

  const handleSelectPrice = (event, value) => {
    if (value) {
      setSelectedOption(value);
      const selectedPrice = prices.find((price) => price.title === value);
      console.log(selectedPrice);

      convertToPriceData(selectedPrice.description);

      setSelectedDescription(selectedPrice.description);
      if (value.includes("PT")) {
        setSelectedLanguageOption("Português");
      } else if (value.includes("EN")) {
        setSelectedLanguageOption("Inglês");
      } else {
        setSelectedLanguageOption("");
      }
    }
  };

  return (
    <Box
      sx={{
        background:
          "linear-gradient(135deg, rgba(255, 200, 150, 0.2), rgba(130, 190, 255, 0.2))",
        marginLeft: "250px",
        width: "calc(100% - 240px)",
        height: "100vh",
        padding: 2,
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            id="combo-box-prices"
            options={prices.map((price) => price.title)}
            onChange={handleSelectPrice}
            sx={{
              width: 300,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lista de Preço"
                variant="outlined"
                fullWidth
              />
            )}
            ListboxComponent={(props) => (
              <div {...props} style={{ maxHeight: 200, overflowY: "auto" }} />
            )}
            renderOption={(props, option) => (
              <ListItem {...props} component="div">
                {option}
              </ListItem>
            )}
          />
        </Grid>
      </Grid>

      <Box sx={{ padding: 2, margin: "0 auto" }}>
        {/* 
         <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="experimental-content"
            id="experimental-header"
          >
            <Typography variant="h6">Experimental</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AutoCopilot priceData={priceData} />
            <AutoCopilot priceData={priceData} />
            <CopilotStyleAutocomplete />
            <AutoCopilot priceData={priceData} />
            <AutoComplete data={flattenJSON(selectedDescription)} />
          </AccordionDetails>
        </Accordion> */}

        {/* Renderização condicional com base no modo */}
        <Box sx={{ marginTop: 4 }}>
          {selectedOption && (
            <AiwithText
              key={selectedDescription}
              language={selectedLanguageOption || "None"}
              description={selectedDescription}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EmailAi;
