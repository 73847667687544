import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Collapse,
  Box,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const EmailList = () => {
  const [emails, setEmails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedEmail, setExpandedEmail] = useState(null);

  // Função para buscar emails com base no searchTerm
  const fetchEmails = () => {
    axios
      .post("https://bramer.tech/emails.php", {
        action: "fetch_emails",
        searchTerm,
      })
      .then((response) => setEmails(response.data))
      .catch((error) => console.error("Error fetching emails:", error));
  };

  // useEffect que chama fetchEmails cada vez que o searchTerm muda
  useEffect(() => {
    fetchEmails();
  }, [searchTerm]);

  // Função para copiar o conteúdo do email para a área de transferência
  const handleCopyEmail = (emailContent) => {
    navigator.clipboard
      .writeText(emailContent)
      .then(() =>
        alert("Conteúdo do email copiado para a área de transferência!")
      )
      .catch((err) => console.error("Falha ao copiar o texto: ", err));
  };

  // Função para alternar o estado de expansão do email
  const toggleExpandEmail = (emailId) => {
    setExpandedEmail((prevEmailId) =>
      prevEmailId === emailId ? null : emailId
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "240px", // Ajuste para o tamanho do sidebar fixo
        padding: 4,
        boxSizing: "border-box",
        width: "calc(100% - 240px)", // Ajustar a largura com base no sidebar
        overflow: "auto",
        height: "100vh",

        background:
          "linear-gradient(135deg, rgba(255, 200, 150, 0.2), rgba(130, 190, 255, 0.2))", // Tons suaves de laranja e azul
      }}
    >
      <Typography variant="h4" gutterBottom>
        Emails
      </Typography>

      <TextField
        label="Buscar Emails"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {emails.map((email) => (
        <Card key={email.email_id} variant="outlined" sx={{ mb: 2 }}>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{email.tag}</Typography>
            <CardActions>
              <IconButton
                onClick={() => handleCopyEmail(email.email)}
                edge="end"
              >
                <ContentCopyIcon />
              </IconButton>
              <IconButton
                onClick={() => toggleExpandEmail(email.email_id)}
                edge="end"
              >
                {expandedEmail === email.email_id ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </CardActions>
          </CardContent>

          <Collapse
            in={expandedEmail === email.email_id}
            timeout="auto"
            unmountOnExit
          >
            <CardContent>
              <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                {email.email}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </Box>
  );
};

export default EmailList;
